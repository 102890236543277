import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'connected-react-router'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'
import { DOMAIN } from './contants'

import {
  createTicketTemplate,
  fetchTicketTemplates,
  mutateticketTemplateResetError,
} from './actions'
import {
  selectMutateTicketTemplatesStatus,
  selectTicketTemplates,
} from './selectors'
import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import messages from './messages'

import styled from 'styled-components'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import BorderedCard from 'components/BorderedCard'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import Icon from 'components/Icon'
import SectionHeader from 'components/SectionHeader'
import CreateModal from 'components/TicketTemplates/TicketTemplateSourceURIModal'
import TicketTemplateList from './components/TicketTemplateList'

const PageContents = styled.div`
  padding: 14px;

  .header-content {
    display: grid;
    grid-template-columns: auto 150px;

    .description-brief {
      color: rgb(128, 124, 124);
      grid-row-start: 2;
      font-size: 14px;
    }
    .create-button-container {
      grid-row-start: 2;
      text-align: right;
    }
  }

  .list-content {
    margin-top: 12px;
    overflow: scroll;
  }
`

export class TicketTemplatesPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      createModalOpen: false,
    }
    props.fetchTicketTemplates()
  }

  closeCreateModal = () => {
    this.setState({ createModalOpen: false })
  }

  openCreateModal = () => {
    this.setState({ createModalOpen: true })
  }

  render() {
    return (
      <PageContents>
        <div className="header-content">
          <SectionHeader>
            <DynamicFormattedMessage {...messages.pageTitle} />
          </SectionHeader>
          <div className="description-brief">
            <DynamicFormattedMessage {...messages.pageDescriptionBrief} />
          </div>
          <div className="create-button-container">
            <WithPermission permissionName="edit.tickettemplates">
              <Button color="primary" onClick={this.openCreateModal}>
                <Icon fa name="plus" />{' '}
                <DynamicFormattedMessage
                  {...messages.createTicketTemplateButtonText}
                />
              </Button>
              <CreateModal
                close={this.closeCreateModal}
                isOpen={this.state.createModalOpen}
                mutateTicketTempaltesStatus={
                  this.props.mutateTicketTempaltesStatus
                }
                mutateticketTemplateResetError={
                  this.props.mutateticketTemplateResetError
                }
                onSave={this.props.createTicketTemplate}
              />
            </WithPermission>
          </div>
        </div>
        <div className="list-content">
          <BorderedCard
            style={{
              minHeight: '400px',
              height: 'calc(100vh - 160px)',
              width: 'calc(100% - 80px)',
              position: 'absolute',
            }}
          >
            <TicketTemplateList
              push={this.props.push}
              ticketTemplates={this.props.ticketTemplates}
              users={this.props.users}
              usersLoading={this.props.usersLoading}
            />
          </BorderedCard>
        </div>
      </PageContents>
    )
  }
}

TicketTemplatesPage.propTypes = {
  // ~ bound action creators ~
  createTicketTemplate: PropTypes.func,
  fetchTicketTemplates: PropTypes.func,
  mutateticketTemplateResetError: PropTypes.func,
  push: PropTypes.func,

  // ~ props from redux ~
  mutateTicketTempaltesStatus: ImmutablePropTypes.contains({
    errors: ImmutablePropTypes.listOf(PropTypes.string),
  }),
  ticketTemplates: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.iterable,
  }),
  users: ImmutablePropTypes.map.isRequired,
  usersLoading: PropTypes.bool,
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createTicketTemplate,
      fetchTicketTemplates,
      mutateticketTemplateResetError,
      push,
    },
    dispatch
  )
}

const mapStateToProps = createStructuredSelector({
  ticketTemplates: selectTicketTemplates,
  mutateTicketTempaltesStatus: selectMutateTicketTemplatesStatus,
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: DOMAIN, reducer })
const withSaga = injectSaga({
  key: DOMAIN,
  saga: sagas,
  mode: DAEMON,
})

export default compose(withConnect, withReducer, withSaga)(TicketTemplatesPage)
