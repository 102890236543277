export const DOMAIN = `TicketTemplatesPage`

export const FETCH_TICKET_TEMPLATES = `app/containers/TicketTemplatesPage/FETCH_TICKET_TEMPLATES`
export const FETCH_TICKET_TEMPLATES_ERROR = `app/containers/TicketTemplatesPage/FETCH_TICKET_TEMPLATES_ERROR`
export const FETCH_TICKET_TEMPLATES_SUCCESS = `app/containers/TicketTemplatesPage/FETCH_TICKET_TEMPLATES_SUCCESS`

export const CREATE_TICKET_TEMPLATE = `app/containers/TicketTemplatesPage/CREATE_TICKET_TEMPLATE`
export const UPDATE_TICKET_TEMPLATE = `app/containers/TicketTemplatesPage/CREATE_TICKET_TEMPLATE`
export const MUTATE_TICKET_TEMPLATE_SUCCESS = `app/containers/TicketTemplatesPage/MUTATE_TICKET_TEMPLATE_SUCCESS`
export const MUTATE_TICEKT_TEMPLATE_ERROR = `app/containers/TicketTemplatesPage/MUTATE_TICEKT_TEMPLATE_ERROR`
export const MUTATE_TICEKT_TEMPLATE_RESET_ERROR = `app/containers/TicketTemplatesPage/MUTATE_TICEKT_TEMPLATE_RESET_ERROR`
