import { all, put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  fetchTicketTemplates as fetchTicketTemplatesAction,
  fetchTicketTemplatesError,
  fetchTicketTemplatesSuccess,
  mutateTicketTemplateError,
  mutateTicketTemplateSuccess,
} from './actions'
import { CREATE_TICKET_TEMPLATE, FETCH_TICKET_TEMPLATES } from './contants'
import {
  CREATE_TICKET_TEMPLATE_MUTATION,
  FETCH_TICKET_TEMPLATES_QUERY,
} from './queries'
import { processBotValidationError } from 'components/TicketTemplates/utils'

function* createTicketTemplate({ payload: { sourceURI } }) {
  try {
    const client = yield getClient()
    const result = yield client.mutate({
      mutation: CREATE_TICKET_TEMPLATE_MUTATION,
      variables: { sourceURI },
    })
    if ((result.errors ?? []).length > 0) {
      const errors = processBotValidationError(result.errors[0])
      yield put(mutateTicketTemplateError(errors))
    } else {
      yield all([
        put(mutateTicketTemplateSuccess()),
        put(fetchTicketTemplatesAction()),
      ])
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an exception happened creating ticket template`, e)
    yield put(mutateTicketTemplateError(['unknown.error']))
  }
}

function* fetchTicketTemplates() {
  try {
    const client = yield getClient()
    const result = yield client.query({
      query: FETCH_TICKET_TEMPLATES_QUERY,
    })

    const path = ['data', 'TicketTemplates', 'items']
    const data = _.get(result, path, null)
    if (data === null) {
      throw new Error(`no data at path ${path}`)
    }
    yield put(fetchTicketTemplatesSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`an exception happened fetching ticket templates`, e)
    yield put(fetchTicketTemplatesError())
  }
}

function* TicketTemplatesSaga() {
  yield all([
    takeLatest([CREATE_TICKET_TEMPLATE], createTicketTemplate),
    takeLatest([FETCH_TICKET_TEMPLATES], fetchTicketTemplates),
  ])
}

export default TicketTemplatesSaga
