import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import {
  CREATE_TICKET_TEMPLATE,
  FETCH_TICKET_TEMPLATES,
  FETCH_TICKET_TEMPLATES_ERROR,
  FETCH_TICKET_TEMPLATES_SUCCESS,
  MUTATE_TICEKT_TEMPLATE_ERROR,
  MUTATE_TICEKT_TEMPLATE_RESET_ERROR,
  MUTATE_TICKET_TEMPLATE_SUCCESS,
} from './contants'

export const initialState = fromJS({
  ticketTemplates: {
    loading: false,
    error: false,
    data: null,
  },
  mutateTicketTemplateStatus: {
    loading: false,
    errors: null,
  },
})

const reducer = handleActions(
  {
    [FETCH_TICKET_TEMPLATES]: state =>
      state
        .setIn(['ticketTemplates', 'loading'], true)
        .setIn(['ticketTemplates', 'error'], false),
    [FETCH_TICKET_TEMPLATES_ERROR]: state =>
      state
        .setIn(['ticketTemplates', 'loading'], false)
        .setIn(['ticketTemplates', 'error'], true),
    [FETCH_TICKET_TEMPLATES_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['ticketTemplates', 'loading'], false)
        .setIn(['ticketTemplates', 'error'], false)
        .setIn(['ticketTemplates', 'data'], fromJS(payload.data)),
    [CREATE_TICKET_TEMPLATE]: state =>
      state
        .setIn(['mutateTicketTemplateStatus', 'loading'], true)
        .setIn(['mutateTicketTemplateStatus', 'error'], null),
    [MUTATE_TICEKT_TEMPLATE_ERROR]: (state, { payload }) =>
      state
        .setIn(['mutateTicketTemplateStatus', 'loading'], false)
        .setIn(['mutateTicketTemplateStatus', 'errors'], fromJS(payload)),
    [MUTATE_TICEKT_TEMPLATE_RESET_ERROR]: state =>
      state.setIn(['mutateTicketTemplateStatus', 'errors'], null),
    [MUTATE_TICKET_TEMPLATE_SUCCESS]: state =>
      state
        .setIn(['mutateTicketTemplateStatus', 'loading'], false)
        .setIn(['mutateTicketTemplateStatus', 'errors'], null),
  },
  initialState
)

export default reducer
