import gql from 'graphql-tag'

export const CREATE_TICKET_TEMPLATE_MUTATION = gql`
  mutation CreateTicketTemplate($sourceURI: String!) {
    CreateTicketTemplate(input: { sourceURI: $sourceURI }) {
      srn
      resourceId
      sourceURI
      sourceContent
      title
      version
      authorName
      authorEmail
      createdDate
      createdBy
      lastModifiedBy
      lastModifiedDate
      fields {
        required
        name
        type
        select {
          path
          search
          labelFields
          valueField
        }
      }
    }
  }
`

export const FETCH_TICKET_TEMPLATES_QUERY = gql`
  query fetchTicketTemplates($filter: TicketTemplatesFilter = {}) {
    TicketTemplates(where: $filter) {
      items(limit: -1) {
        srn
        resourceId
        sourceURI
        sourceContent
        title
        version
        authorName
        authorEmail
        createdDate
        createdBy
        lastModifiedBy
        lastModifiedDate
        fields {
          required
          name
          type
          select {
            path
            search
            labelFields
            valueField
          }
        }
      }
    }
  }
`
