import { createAction } from 'redux-actions'
import {
  FETCH_TICKET_TEMPLATES,
  FETCH_TICKET_TEMPLATES_ERROR,
  FETCH_TICKET_TEMPLATES_SUCCESS,
  CREATE_TICKET_TEMPLATE,
  UPDATE_TICKET_TEMPLATE,
  MUTATE_TICKET_TEMPLATE_SUCCESS,
  MUTATE_TICEKT_TEMPLATE_ERROR,
  MUTATE_TICEKT_TEMPLATE_RESET_ERROR,
} from './contants'

export const fetchTicketTemplates = createAction(FETCH_TICKET_TEMPLATES)
export const fetchTicketTemplatesError = createAction(
  FETCH_TICKET_TEMPLATES_ERROR
)
export const fetchTicketTemplatesSuccess = createAction(
  FETCH_TICKET_TEMPLATES_SUCCESS
)

export const createTicketTemplate = createAction(CREATE_TICKET_TEMPLATE)
export const updateTicketTemplate = createAction(UPDATE_TICKET_TEMPLATE)
export const mutateTicketTemplateSuccess = createAction(
  MUTATE_TICKET_TEMPLATE_SUCCESS
)
export const mutateTicketTemplateError = createAction(
  MUTATE_TICEKT_TEMPLATE_ERROR
)
export const mutateticketTemplateResetError = createAction(
  MUTATE_TICEKT_TEMPLATE_RESET_ERROR
)
