import { defineMessages } from 'react-intl'

export default defineMessages({
  createTicketTemplateButtonText: {
    id: 'app.containers.TicketTemplatesPage.createTicketTemplateButtonText',
    defaultMessage: 'New',
  },
  createModalHeader: {
    id: 'app.containers.TicketTemplatesPage.createModalHeader',
    defaultMessage: 'Create Custom Ticket Template',
  },
  errorLoadingTemplates: {
    id: 'app.containers.TicketTemplatesPage.errorLoadingTemplates',
    defaultMessage: 'There was an error loading ticket templates',
  },
  pageDescriptionBrief: {
    id: 'app.containers.TicketTemplatesPage.pageDescriptionBrief',
    // TODO put a message that makes sense here:
    defaultMessage:
      'Custom ticket templates allow you to define extra fields for you to capture necessary ticket data.',
  },
  pageTitle: {
    id: 'app.containers.TicketTemplatesPage.pageTitle',
    defaultMessage: 'Custom Ticket Templates',
  },
})
