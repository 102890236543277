import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { isImmutable } from 'immutable'
import qs from 'querystring'

import BarLoadingAnimation from 'components/BarLoadingAnimation'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import UserWidget from 'components/UserWidget'

import messages from '../messages'

/**
 * display as a list of field names
 */
function formatFieldsForDisplay({ fields }) {
  return (fields ?? []).map(({ name }) => name).join(', ')
}

/**
 * format the data for our table
 */
function formatTableData(data) {
  if (isImmutable(data)) {
    return formatTableData(data.toJS())
  }
  if (!data) {
    return []
  }
  return data.map(row => {
    return {
      title: row.title,
      description: row.description,
      fields: formatFieldsForDisplay(row),
      authorName: row.authorName,
      authorEmail: row.authorEmail,
      createdDate: row.createdDate,
      createdBy: row.createdBy,
      lastModifiedDate: row.lastModifiedDate,
      ['Last Modified By']: row.lastModifiedBy,
      resourceId: row.resourceId,
      srn: row.srn,
    }
  })
}

/**
 * define a component for this to avoid linter warnings
 */
function UserInTable({ value }) {
  return <UserWidget srn={value} table />
}
UserInTable.propTypes = { value: PropTypes.string.isRequired }

/**
 * get custom column config for ag-grid
 */
function getCustomColumnConfig() {
  return {
    createdBy: {
      cellRendererFramework: UserInTable,
    },
    ['Last Modified By']: {
      cellRendererFramework: UserInTable,
    },
  }
}

export default function TicketTemplateList(props) {
  if (props.ticketTemplates.get('loading')) {
    return <BarLoadingAnimation />
  }

  if (props.ticketTemplates.get('error')) {
    return <DynamicFormattedMessage {...messages.errorLoadingTemplates} />
  }

  return (
    <DataTable
      data={formatTableData(props.ticketTemplates.get('data'))}
      customColumnConfig={getCustomColumnConfig()}
      onDoubleClickRow={row => {
        props.push({
          pathname: '/App/TicketTemplateDetails',
          search: qs.stringify({ srn: row.srn }),
        })
      }}
    />
  )
}

TicketTemplateList.propTypes = {
  push: PropTypes.func.isRequired,
  ticketTemplates: ImmutablePropTypes.contains({
    error: PropTypes.bool,
    loading: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.iterable),
  }),
}
