import { createSelector } from 'reselect'

import { DOMAIN } from './contants'
import { initialState } from './reducers'

export const selectDomain = state => state.get(DOMAIN) || initialState

export const selectTicketTemplates = createSelector(selectDomain, state =>
  state.get('ticketTemplates')
)

export const selectMutateTicketTemplatesStatus = createSelector(
  selectDomain,
  state => state.get('mutateTicketTemplateStatus')
)
